import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import {
  EAttachmentType,
  attachmentTypeLang,
} from "../../../../../core/domain/entities/attachmentsGridTypes";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IValidateImportDataContract } from "../../domain/contracts/validateImportDataContract";
import { IDebtImportAssessmentEntity } from "../../domain/entities/debtImportAssessmentEntity";
import { DebtImportEntity } from "../../domain/entities/debtImportEntity";
import { IDebtImportForm } from "../../domain/entities/debtImportTypes";
import {
  IDebtImportPayloadModel,
  IDebtImportResponseModel,
} from "../models/debtImportModel";
import { MainDebtImportService } from "./mainDebtImportService";

export class ValidateImportDataService
  extends MainDebtImportService
  implements IValidateImportDataContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async validateImportData({ imports }: IDebtImportForm) {
    const userEntity = this.getUserLocalService.get();
    const url = "/AccountPayableImportations/Validate";

    const payload = imports.map<Partial<IDebtImportPayloadModel>>(
      accountData => {
        return {
          accountAlreadyPaid: accountData.accountAlreadyPaid,
          assessments: accountData.assessments.map(assessment => ({
            classificationAssessmentId:
              (assessment.classificationAssessment?.rawValue as string) || "",
            classificationAssessmentName:
              (assessment.classificationAssessment?.label as string) || "",
            costCenterId: (assessment.costCenter?.rawValue as string) || "",
            costCenterName: (assessment.costCenter?.label as string) || "",
            fieldErrors: assessment.fieldErrors,
            hasError: assessment.hasError,
            observation: assessment.observation,
            percentage: assessment.percentage,
            value: assessment.value,
            valueAssessmentLeft: assessment.valueAssessmentLeft,
          })),
          barcode: accountData?.barcode || "",
          classificationAccountId:
            (accountData.classificationAccount?.rawValue as string) || "",
          classificationAccountName: accountData.classificationAccount?.label,
          classificationUspId:
            (accountData.classificationUsp?.rawValue as string) || "",
          classificationUspName: accountData.classificationUsp?.label,
          companyId: (accountData.company?.rawValue as string) || "",
          companyName: accountData.company?.label,
          competencyId: (accountData.competency?.rawValue as string) || "",
          competencyName: accountData.competency?.label,
          description: accountData.description,
          documentNumber: accountData.documentNumber,
          documentStatus: accountData.documentStatus?.key,
          getCompanyHasProject: accountData.getCompanyHasProject,
          getCompetencyIsUsp: accountData.getCompetencyIsUsp,
          id: accountData.id,
          issueDate: this.dateFormatter(accountData.issueDate),
          observation: accountData.observation,
          paymentAccountId:
            (accountData.paymentAccount?.rawValue as string) || "",
          paymentAccountName: accountData.paymentAccount?.label,
          paymentMethod: accountData.paymentMethod?.key,
          payUntil: this.dateFormatter(accountData.payUntil),
          projectId: (accountData.project?.rawValue as string) || "",
          projectName: accountData.project?.label,
          providerId: (accountData.provider?.rawValue as string) || "",
          providerName: accountData.provider?.label || "",
          storageFiles: accountData.storageFiles.map(f => ({
            ...f,
            file: f.file || null,
            barcode: f.barcode || "",
            storageFileId: f.storageFileId || "",
          })),
          terminationDate: this.dateFormatter(accountData.terminationDate),
          value: accountData.value,
          fuspPurchaseOrderId:
            accountData.fuspPurchaseOrderId?.toString() || "",
          correspondingProviderName: accountData.correspondingProviderName,
        };
      },
    );

    const response = await this.api.post<IDebtImportResponseModel[]>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    /** É necessário que as linhas inconsistentes sejam as primeiras linhas. */
    const orderedList = response.sort(a => {
      return a.hasError ? -1 : 0;
    });

    const validatedData = orderedList.map(responseData => {
      const datePayUntil = responseData.payUntil
        ? new Date(responseData.payUntil)
        : "";
      const dateIssueDate = responseData.issueDate
        ? new Date(responseData.issueDate)
        : "";
      const dateTerminationDate = responseData?.terminationDate
        ? new Date(responseData.terminationDate)
        : "";

      const provider = this.buildTypeaheadObject(responseData, "provider");
      const company = this.buildTypeaheadObject(responseData, "company");
      const competency = this.buildTypeaheadObject(responseData, "competency");

      if (company) {
        company.metadata = {
          hasProject: responseData.getCompanyHasProject,
        };
      }

      if (competency) {
        competency.metadata = {
          isUsp: responseData.getCompetencyIsUsp,
        };
      }

      if (provider && typeof responseData.providerDocumentType === "number") {
        provider.metadata = {
          documentType: responseData.providerDocumentType,
        };
      }

      const storageFiles =
        responseData?.storageFiles?.map(file => ({
          ...file,
          active: true,
          typeDescription: attachmentTypeLang[file.type],
        })) || [];

      return new DebtImportEntity({
        id: responseData.id,
        accountAlreadyExists: responseData?.accountAlreadyExists || false,
        accountAlreadyExistsForAnotherUser:
          responseData?.accountAlreadyExistsForAnotherUser || false,
        accountAlreadyPaid: responseData?.accountAlreadyPaid || false,
        assessments:
          responseData?.assessments?.map<IDebtImportAssessmentEntity>(
            assessment => ({
              hasError: assessment.hasError,
              fieldErrors: assessment.fieldErrors,
              observation: assessment.observation || "",
              value: new BigNumber(assessment.value).toJSON(),
              valueAssessmentLeft: assessment.valueAssessmentLeft,
              costCenter: this.buildTypeaheadObject(assessment, "costCenter"),
              percentage: new BigNumber(assessment.percentage).toJSON(),
              classificationAssessment: this.buildTypeaheadObject(
                assessment,
                "classificationAssessment",
              ),
            }),
          ) || [],
        barcode: this.defineBarcode(responseData),
        classificationAccount: this.buildTypeaheadObject(
          responseData,
          "classificationAccount",
        ),
        classificationUsp: this.buildTypeaheadObject(
          responseData,
          "classificationUsp",
        ),
        company,
        competency,
        description: responseData?.description?.toUpperCase() || "",
        documentNumber: responseData?.documentNumber?.toUpperCase() || "",
        documentStatus: {
          key: responseData?.documentStatus ?? "",
          value: responseData.documentStatusDescription,
        },
        getCompanyHasProject: responseData.getCompanyHasProject,
        getCompetencyIsUsp: responseData.getCompetencyIsUsp,
        hasAssessmentError: responseData.hasAssessmentError,
        hasError: responseData.hasError,
        issueDate: dateIssueDate ? format(dateIssueDate, "dd/MM/yyyy") : "",
        observation: responseData?.observation?.toUpperCase() || "",
        payUntil: datePayUntil ? format(datePayUntil, "dd/MM/yyyy") : "",
        paymentAccount: this.buildTypeaheadObject(
          responseData,
          "paymentAccount",
        ),
        paymentMethod: {
          key: responseData?.paymentMethod ?? "",
          value: responseData.paymentMethodDescription,
        },
        project: this.buildTypeaheadObject(responseData, "project"),
        provider,
        storageFiles,
        terminationDate: dateTerminationDate
          ? format(dateTerminationDate, "dd/MM/yyyy")
          : "",
        value: responseData.value,
        valueAssessmentLeft: responseData.valueAssessmentLeft,
        fieldErrors: responseData.fieldErrors,
        fuspPurchaseOrderId: responseData.fuspPurchaseOrderId?.toString() || "",
        correspondingProviderName:
          responseData?.correspondingProviderName || "",
      });
    });

    return validatedData;
  }

  private defineBarcode(data: IDebtImportResponseModel) {
    if (data?.barcode) {
      return data.barcode;
    }

    const billetAttachment = data?.storageFiles?.find(attachment => {
      return Number(attachment?.type) === EAttachmentType.Billet;
    });

    return billetAttachment?.barcode || "";
  }
}
