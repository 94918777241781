import { useMemo } from "react";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../advTable/domain/entities/advTableColumn";
import { SimpleTableColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  approvalStatusLang,
  costCenterPaymentMethodLang,
  costCenterReportOriginLang,
  costCenterReportStatusLang,
  EApprovalStatus,
  ECostCenterReportStatus,
  EPaymentMethod,
  EReportOrigin,
  ICostCenterReportEntity,
} from "../../domain/entities/costCenterReportEntity";
import { MakeCostCenterReportManager } from "../../main/makeCostCenterReportManager";

export enum EEditModalMode {
  edit,
  readonly,
}

interface IUseCostCenterReportGridParams {
  useCostCenterReportManager: MakeCostCenterReportManager;
}

export function useCostCenterReportGridManager({
  useCostCenterReportManager,
}: IUseCostCenterReportGridParams) {
  const originMap: Record<EReportOrigin, string> = useMemo(
    () => ({
      [EReportOrigin.OpeningBalance]: "",
      [EReportOrigin.accountPayable]: "(rateio)",
      [EReportOrigin.accountReceivable]: "(parcela)",
      [EReportOrigin.transaction]: "",
    }),
    [],
  );

  const {
    searchUser,
    searchCompany,
    searchCustomerProvider,
    searchManagerCostCenter,
    searchClassificationAssessment,
    searchMacroCategories,
  } = useCostCenterReportManager;

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Status",
        field: "statusDescription",
        searchable: true,
        orderable: true,
        sortField: "status",
        searchField: "status",
        columnType: EColumnType.enum,
        enumObject: ECostCenterReportStatus,
        enumLang: costCenterReportStatusLang,
        width: "124px",
        className: "text-truncate",
      },
      {
        header: "Origem",
        field: "originDescription",
        searchable: true,
        orderable: true,
        sortField: "origin",
        searchField: "origin",
        className: "text-truncate",
        columnType: EColumnType.enum,
        enumObject: EReportOrigin,
        enumLang: costCenterReportOriginLang,
        width: "130px",
      },
      {
        header: "Empresa",
        field: "companyName",
        orderable: true,
        searchable: true,
        searchField: "companyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "", fetchMinLength = 10, companyGroupId = id) {
          return searchCompany({
            search,
            companyGroupId,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Status aprovação",
        field: "approvalStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "approvalStatus",
        searchField: "approvalStatus",
        className: "text-truncate",
        columnType: EColumnType.enum,
        enumObject: EApprovalStatus,
        enumLang: approvalStatusLang,
        width: "164px",
      },
      {
        header: "Data pgto/recebimento",
        field: "operationDateString",
        searchable: true,
        orderable: true,
        searchField: "operationDate",
        sortField: "operationDate",
        columnType: EColumnType.date,
        width: "186px",
        className: "text-truncate",
      },
      {
        header: "Centro de custo",
        field: "costCenterName",
        searchField: "costCenterId",
        className: "text-truncate",
        width: "180px",
        orderable: true,
        searchable: true,
        columnType: EColumnType.relationship,
        async getList(search = "", fetchMinLength = 10, companyGroupId = id) {
          return searchManagerCostCenter({
            search,
            companyGroupId,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Data de emissão",
        field: "issueDateString",
        searchable: true,
        orderable: true,
        searchField: "issueDate",
        sortField: "issueDate",
        columnType: EColumnType.date,
        width: "176px",
        className: "text-truncate",
      },
      {
        header: "Macrocategorias",
        field: "macroBoxName",
        orderable: true,
        searchable: true,
        width: "180px",
        className: "text-truncate",
        searchField: "macroBoxId",
        columnType: EColumnType.relationship,
        async getList(search = "", fetchMinLength = 10) {
          return searchMacroCategories({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Classificação de rateio",
        field: "classificationAssessmentName",
        orderable: true,
        searchable: true,
        searchField: "classificationAssessmentId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "218px",
        async getList(search = "", fetchMinLength = 10, companyGroupId = id) {
          return searchClassificationAssessment({
            search,
            companyGroupId,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      // descrição
      {
        header: "Descrição",
        orderable: false,
        field: "description",
        className: "text-truncate",
        width: "140px",
      },
      // descricao do rateio
      {
        header: "Descrição do rateio",
        orderable: false,
        field: "observation",
        className: "text-truncate",
        width: "204px",
        bodyTemplate(rowData: ICostCenterReportEntity) {
          const { observation = "" } = rowData;
          return (
            <span title={observation} className="cell-truncate">
              {observation}
            </span>
          );
        },
      },
      {
        header: "Cliente / Fornecedor",
        field: "customerProviderName",
        searchField: "customerProviderId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50, companyGroupId = id) {
          return searchCustomerProvider({
            search,
            companyGroupId,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Nº documento",
        orderable: false,
        field: "documentNumber",
        className: "text-truncate",
        width: "168px",
      },
      {
        header: "Valor da operação",
        field: "operationValueString",
        sortField: "operationValue",
        searchField: "operationValue",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "198px",
        className: "text-truncate",
        bodyTemplate(rowData: ICostCenterReportEntity) {
          const { operationValueString, origin } = rowData;
          const valueDescription = originMap[origin];

          return (
            <div
              className="cell-truncate"
              title={`${operationValueString} ${valueDescription}`}
            >
              {operationValueString}{" "}
              {valueDescription && (
                <small className="text-muted">{valueDescription}</small>
              )}
            </div>
          );
        },
      },
      {
        header: "Método de pagamento",
        field: "paymentMethodDescription",
        searchable: true,
        orderable: true,
        sortField: "paymentMethod",
        searchField: "paymentMethod",
        columnType: EColumnType.enum,
        enumObject: EPaymentMethod,
        enumLang: costCenterPaymentMethodLang,
        className: "text-truncate",
        width: "216px",
      },
      {
        header: "Data de lançamento",
        field: "dateCreatedString",
        searchable: true,
        orderable: true,
        searchField: "dateCreated",
        sortField: "dateCreated",
        columnType: EColumnType.date,
        width: "204px",
        className: "text-truncate",
      },
      {
        header: "Solicitado por",
        field: "requestedUserName",
        searchable: true,
        orderable: true,
        searchField: "requestedUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Lançado por",
        field: "userNameCreated",
        searchable: true,
        orderable: true,
        searchField: "userCreated",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      { field: "operationValue", hidden: true },
      { field: "operationDate", hidden: true },
      { field: "originBarcode", hidden: true },
      { field: "totalValue", hidden: true },
      { field: "originId", hidden: true },
      { field: "origin", hidden: true },
      { field: "type", hidden: true },
      { field: "description", hidden: true },
    ];
  }, [
    searchCompany,
    id,
    searchManagerCostCenter,
    searchClassificationAssessment,
    searchCustomerProvider,
    originMap,
    searchUser,
    searchMacroCategories,
  ]);

  return {
    columns,
  };
}
