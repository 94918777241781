import { useMemo } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../../advTable/domain/entities/advTableColumn";
import { EEnumListBoxOptions } from "../../../../../advTable/presentation/components/ColumnFilterEnum";
import { SimpleTableColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EAccountPayableDocumentStatus,
  EAccountPayablePaymentMethod,
  EAccountPayableStatus,
  EFuspSendingStatus,
  EReturnStatus,
  IAccountPayableListItemEntity,
  accountPayableDocumentStatusLang,
  accountPayablePaymentMethodLang,
  accountPayableStatusLang,
  fuspSendingStatusLang,
  returnStatusLang,
} from "../../domain/entities/accountPayableListItemEntity";
import { MakeAccountsPayable } from "../../main/makeAccountsPayable";
import { BadgeStatus } from "../components/BadgeStatus/styles";

interface IUseAccountsPayableGridParams {
  useAccountsPayable: MakeAccountsPayable;
}

export function useAccountsPayableGrid({
  useAccountsPayable,
}: IUseAccountsPayableGridParams) {
  const {
    searchUser,
    searchProject,
    searchCompany,
    searchProvider,
    searchCompetency,
    searchPaymentAccount,
    searchClassificationUsp,
    searchClassificationAccount,
  } = useAccountsPayable;

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Empresa",
        field: "companyName",
        orderable: true,
        searchable: true,
        searchField: "companyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "140px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompany({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Nº documento",
        field: "documentNumber",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Valor",
        field: "valueString",
        sortField: "value",
        searchField: "value",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Competência",
        field: "competencyName",
        orderable: true,
        searchable: true,
        searchField: "competencyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "158px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompetency({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Projeto",
        field: "projectName",
        orderable: true,
        searchable: true,
        searchField: "projectId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "", fetchMinLength = 10) {
          return searchProject({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Pagar em",
        field: "payUntilString",
        orderable: true,
        searchable: true,
        searchField: "payUntil",
        sortField: "payUntil",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "200px",
      },
      {
        header: "Fornecedor",
        field: "providerName",
        searchField: "providerId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchProvider({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Método de pagamento",
        field: "paymentMethodDescription",
        searchable: true,
        orderable: true,
        sortField: "paymentMethod",
        searchField: "paymentMethod",
        columnType: EColumnType.enum,
        enumObject: EAccountPayablePaymentMethod,
        enumLang: accountPayablePaymentMethodLang,
        className: "text-truncate",
        width: "216px",
      },
      {
        header: "Classificação USP",
        field: "classificationUspName",
        orderable: true,
        searchable: true,
        searchField: "classificationUspId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "180px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationUsp({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Descrição",
        field: "description",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Observação",
        field: "observation",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Conta de pagamento",
        field: "paymentAccountName",
        orderable: true,
        searchable: true,
        searchField: "paymentAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 10) {
          return searchPaymentAccount({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Classificação contábil",
        field: "classificationAccountName",
        orderable: true,
        searchable: true,
        searchField: "classificationAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "212px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationAccount({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Data de emissão",
        field: "issueDateString",
        searchable: true,
        orderable: true,
        searchField: "issueDate",
        sortField: "issueDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "176px",
      },
      {
        header: "Lançado por",
        field: "userNameCreated",
        searchable: true,
        orderable: true,
        searchField: "userCreated",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Data de baixa",
        field: "terminationDateString",
        searchable: true,
        orderable: true,
        searchField: "terminationDate",
        sortField: "terminationDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "176px",
      },
      {
        header: "Baixado por",
        field: "terminationUserName",
        searchable: true,
        orderable: true,
        searchField: "terminationUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Status da documentação",
        field: "documentStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "documentStatus",
        searchField: "documentStatus",
        columnType: EColumnType.enum,
        enumObject: EAccountPayableDocumentStatus,
        enumLang: accountPayableDocumentStatusLang,
        className: "text-truncate",
        width: "230px",
      },
      {
        header: "Status",
        field: "statusDescription",
        searchable: false,
        orderable: true,
        searchField: "status",
        columnType: EColumnType.enum,
        enumObject: EAccountPayableStatus,
        enumLang: accountPayableStatusLang,
        width: "200px",
        className: "text-truncate",
        bodyTemplate(rowData: IAccountPayableListItemEntity) {
          const { status, statusDescription } = rowData;
          return (
            <BadgeStatus title={statusDescription} status={status}>
              {statusDescription}
            </BadgeStatus>
          );
        },
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | EAccountPayableStatus;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }

          const status = value as EAccountPayableStatus;
          return (
            <BadgeStatus title={label} status={status}>
              {label}
            </BadgeStatus>
          );
        },
      },
      {
        header: "Status FUSP",
        field: "fuspSendingStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "fuspSendingStatus",
        searchField: "fuspSendingStatus",
        columnType: EColumnType.enum,
        enumObject: EFuspSendingStatus,
        enumLang: fuspSendingStatusLang,
        className: "text-truncate",
        width: "158px",
      },
      {
        header: "Status devolução",
        field: "returnStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "returnStatus",
        searchField: "returnStatus",
        columnType: EColumnType.enum,
        enumObject: EReturnStatus,
        enumLang: returnStatusLang,
        className: "text-truncate",
        width: "210px",
      },
      {
        header: "Data do cancelamento",
        field: "deleteDateString",
        searchable: true,
        orderable: true,
        searchField: "deleteDate",
        sortField: "deleteDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Cancelado por",
        field: "deleteUserName",
        searchable: true,
        orderable: true,
        searchField: "deleteUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Motivo do cancelamento",
        field: "deleteReason",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Data de ret. baixa",
        field: "chargebackDateString",
        searchable: true,
        orderable: true,
        searchField: "chargebackDate",
        sortField: "chargebackDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Data do lançamento",
        field: "dateCreatedString",
        searchable: true,
        orderable: true,
        searchField: "dateCreated",
        sortField: "dateCreated",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Solicitado por",
        field: "requestedUserName",
        searchable: true,
        orderable: true,
        searchField: "requestedUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Aprovado por",
        field: "approvedUserName",
        searchable: true,
        orderable: true,
        searchField: "approvedUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Data da aprovação",
        field: "approvedDateString",
        searchable: true,
        orderable: true,
        searchField: "approvedDate",
        sortField: "approvedDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Baixa retirada por",
        field: "chargebackUserName",
        searchable: true,
        orderable: true,
        searchField: "chargebackUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Data da devolução",
        field: "returnDateString",
        searchable: true,
        orderable: true,
        searchField: "returnDate",
        sortField: "returnDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Devolvido por",
        field: "returnUserName",
        searchable: true,
        orderable: true,
        searchField: "returnUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Data de modificação",
        field: "dateModifiedString",
        searchable: true,
        orderable: true,
        searchField: "dateModified",
        sortField: "dateModified",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Atualizado por",
        field: "userNameModified",
        searchable: true,
        orderable: true,
        searchField: "userModified",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      { field: "isTax", hidden: true },
      { field: "status", hidden: true },
      { field: "returnStatus", hidden: true },
      { field: "providerRetainFee", hidden: true },
      { field: "isFeeRetention", hidden: true },
      { field: "remittanceDate", hidden: true },
      { field: "remittanceUserId", hidden: true },
      { field: "remittanceUserName", hidden: true },
      { field: "fuspSendingFailureReason", hidden: true },
      { field: "providerDocumentType", hidden: true },
      { field: "pecegePayFeeId", hidden: true },
    ];
  }, [
    id,
    searchClassificationAccount,
    searchClassificationUsp,
    searchCompany,
    searchCompetency,
    searchPaymentAccount,
    searchProject,
    searchProvider,
    searchUser,
  ]);

  return {
    columns,
  };
}
