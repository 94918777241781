import { StatusCodes } from "http-status-codes";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import {
  ApiService,
  IApiError,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../../../core/data/services/viaCepApiService";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { SoulSpinner } from "../../../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import {
  MakeProvider,
  makeProvider,
} from "../../../../../../provider/main/makeProvider";
import {
  AccountPayableFormEntity,
  EAccountPayableStatus,
  EReturnStatus,
  IAccountPayableFormEntity,
} from "../../../domain/entities/accountPayableFormEntity";
import {
  MakeAccountsPayableForm,
  makeAccountsPayableForm,
} from "../../../main/makeAccountPayableForm";
import { SectionAccountInfo } from "../SectionAccountInfo";
import { SectionAditionalInfo } from "../SectionAditionalInfo";
import { SectionAssessment } from "../SectionAssessment";
import { SectionAttachment } from "../SectionAttachment";
import { SectionCompanyData } from "../SectionCompanyData";
import { SectionFooter } from "../SectionFooter";
import { SectionFuspInfo } from "../SectionFuspInfo";
import { SectionProjectInfo } from "../SectionProjectInfo";
import { Container } from "./styles";

export enum EFormMode {
  Create,
  Duplicate,
  Edit,
  Readonly,
}

interface AccountsPayableFormPageState {
  mode: EFormMode;
  isLoading: boolean;
  options: {
    paymentMethods: IEnum[];
    documentStatuses: IEnum[];
  };
}

interface IAccountsPayableFormPageProps {
  useAccountsPayableForm: MakeAccountsPayableForm;
  useProvider: MakeProvider;
}

function AccountsPayableFormPage(props: IAccountsPayableFormPageProps) {
  const { useAccountsPayableForm, useProvider } = props;

  const { fetchPaymentMethods, fetchDocumentStatus, getAccountPayableById } =
    useAccountsPayableForm;

  const navigate = useNavigate();
  const dialog = useSoulDialog();
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<AccountsPayableFormPageState>({
    isLoading: true,
    mode: EFormMode.Edit,
    options: {
      paymentMethods: [],
      documentStatuses: [],
    },
  });

  const { payableId } = useParams<"payableId">();

  const form = useForm<IAccountPayableFormEntity>({
    mode: "all",
  });

  const { reset, getValues, setValue } = form;

  const readonly = state.mode === EFormMode.Readonly;

  const defineFormMode = useCallback(
    (accountPayable: IAccountPayableFormEntity) => {
      if (accountPayable.duplicateAccountPayableId) {
        return EFormMode.Duplicate;
      }

      if (payableId === "new") {
        return EFormMode.Create;
      }

      if (
        accountPayable?.pecegePayFeeId ||
        accountPayable.isTax ||
        accountPayable.returnStatus !== EReturnStatus.NotReturned
      ) {
        return EFormMode.Readonly;
      }

      return EFormMode.Edit;
    },
    [payableId],
  );

  const defineFormValues = useCallback(async () => {
    if (!payableId) {
      return Promise.reject();
    }

    const duplicateId = getValues("duplicateAccountPayableId");

    const isCreating = payableId === "new";
    const isDuplicating = duplicateId && isCreating;

    if (isCreating && !isDuplicating) {
      const defaultValues = new AccountPayableFormEntity();
      reset(defaultValues);
      return defaultValues;
    }

    const idToFetch = isDuplicating ? duplicateId : payableId;

    const accountPayable = await getAccountPayableById(idToFetch);

    if (isDuplicating) {
      const duplicatedAccountPayable = {
        ...accountPayable,
        payUntil: "",
        attachments: [],
        documentNumber: "",
        issueAsPaid: false,
        terminationDate: "",
        status: EAccountPayableStatus.Open,
        duplicateAccountPayableId: idToFetch,
        returnStatus: EReturnStatus.NotReturned,
        assessments: accountPayable.assessments.map(_ass => {
          return {
            ..._ass,
            value: "0",
            accountPayableId: "",
          };
        }),
      };

      reset(duplicatedAccountPayable);

      return duplicatedAccountPayable;
    }

    reset(accountPayable);

    return accountPayable;
  }, [getAccountPayableById, getValues, payableId, reset]);

  const loadFormData = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const accountPayable = await defineFormValues();
      const paymentMethods = await fetchPaymentMethods();
      const documentStatuses = await fetchDocumentStatus();
      const mode = defineFormMode(accountPayable);

      setState(prevState => ({
        ...prevState,
        mode,
        isLoading: false,
        options: {
          paymentMethods,
          documentStatuses,
        },
      }));
    } catch (error) {
      const typedError = error as IApiError;

      if (typedError?.response?.status === StatusCodes.NOT_FOUND) {
        await dialog.fire({
          icon: "error",
          title: "Ops!",
          html: (
            <>
              Conta a pagar não encontrada.
              <br />
              Por favor, verifique os dados e tente novamente.
            </>
          ),
        });
      }

      navigate(SoulRoutes.ACCOUNTS_PAYABLE.path);
    }
  }, [
    dialog,
    navigate,
    defineFormMode,
    defineFormValues,
    fetchPaymentMethods,
    fetchDocumentStatus,
  ]);

  useEffect(() => {
    const urlHasSearchParam = searchParams.has("duplicate");

    /**
     * Encerra o processo de montagem do formulário caso exista um query param
     * na URL.
     *
     * Isso é necessário para que o componente abra com a URL "limpa".
     *
     * Caso exista um ID de duplicação, ele fica salvo na inicialização do
     * estado do formulário.
     */
    if (urlHasSearchParam) {
      const duplicateAccountPayableId = searchParams.get("duplicate");
      setValue("duplicateAccountPayableId", duplicateAccountPayableId);

      const blankParams = new URLSearchParams();
      setSearchParams(blankParams, { replace: true });

      return;
    }

    loadFormData();
  }, [loadFormData, searchParams, setSearchParams, setValue]);

  return (
    <Container>
      <Page>
        <header>
          <h4>
            {payableId === "new"
              ? "Adicionando lançamento"
              : "Editando lançamento"}
          </h4>
        </header>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner
              style={{
                width: "50px",
                height: "50px",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            />
          </div>
        ) : null}
        {!state.isLoading ? (
          <FormProvider {...form}>
            <article className="custom-article fill-height">
              <form className="form-container">
                <div className="row">
                  <div className="col-6">
                    <Card>
                      <section>
                        <SectionCompanyData
                          readonly={readonly}
                          useAccountsPayableForm={useAccountsPayableForm}
                        />
                        <SectionAccountInfo
                          readonly={readonly}
                          options={state.options}
                          useProvider={useProvider}
                          useAccountsPayableForm={useAccountsPayableForm}
                        />
                        <SectionProjectInfo
                          readonly={readonly}
                          useAccountsPayableForm={useAccountsPayableForm}
                        />
                        <SectionFuspInfo readonly={readonly} />
                        <SectionAditionalInfo
                          readonly={readonly}
                          useAccountsPayableForm={useAccountsPayableForm}
                        />
                        <SectionAttachment
                          readonly={readonly}
                          useAccountsPayableForm={useAccountsPayableForm}
                        />
                      </section>
                    </Card>
                  </div>
                  <div className="col-6 right-col">
                    <SectionAssessment
                      mode={state.mode}
                      readonly={readonly}
                      useAccountsPayableForm={useAccountsPayableForm}
                    />
                  </div>
                </div>
                <SectionFooter
                  mode={state.mode}
                  readonly={readonly}
                  useProvider={useProvider}
                  useAccountsPayableForm={useAccountsPayableForm}
                />
              </form>
            </article>
          </FormProvider>
        ) : null}
      </Page>
    </Container>
  );
}

interface IAccountsPayableFormPageFactoryProps {
  api: IApiService;
}

export function AccountsPayableFormPageFactory({
  api,
}: IAccountsPayableFormPageFactoryProps) {
  const { REACT_APP_SERVER_URL, REACT_APP_API_VERSION } = process.env;

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);

  const viaCepApi = new ViaCepApiService();

  const useProvider = makeProvider(api, viaCepApi);
  const useAccountsPayableForm = makeAccountsPayableForm(api, barcodeApi);

  return (
    <AccountsPayableFormPage
      useAccountsPayableForm={useAccountsPayableForm}
      useProvider={useProvider}
    />
  );
}
