import { useMemo } from "react";
import { useCurrentCompanyGroup } from "../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EColumnType } from "../../../../../advTable/domain/entities/advTableColumn";
import { EEnumListBoxOptions } from "../../../../../advTable/presentation/components/ColumnFilterEnum";
import { SimpleTableColumn } from "../../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  EAccountReceivableDocumentStatus,
  EAccountReceivablePaymentMethod,
  EAccountReceivableStatus,
  EInvoiceStatus,
  EReturnStatus,
  IAccountReceivableParcelListItemEntity,
  accountReceivableDocumentStatusLang,
  accountReceivablePaymentMethodLang,
  accountReceivableStatusLang,
  invoiceStatusLang,
  returnStatusLang,
} from "../../domain/entities/accountReceivableListItemEntity";
import { MakeAccountsReceivable } from "../../main/makeAccountsReceivable";
import {
  BadgeInvoiceStatus,
  BadgeStatus,
} from "../components/BadgeStatus/styles";

interface IUseAccountsReceivableGridParams {
  useAccountsReceivable: MakeAccountsReceivable;
}

export function useAccountsReceivableGrid({
  useAccountsReceivable,
}: IUseAccountsReceivableGridParams) {
  const {
    searchUser,
    searchProject,
    searchCompany,
    searchCustomer,
    searchCompetency,
    searchCostCenter,
    searchPaymentAccount,
    searchClassificationUsp,
    searchClassificationAccount,
    // searchClassificationAssessment - Ainda não é utilizado.
  } = useAccountsReceivable;

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        header: "Empresa",
        field: "companyName",
        orderable: true,
        searchable: true,
        searchField: "companyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "140px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompany({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Nº documento",
        field: "documentNumber",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Serial",
        field: "serial",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "TID",
        field: "creditCardLimitTransactionId",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Valor da parcela",
        field: "valueString",
        sortField: "value",
        searchField: "value",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Valor do lançamento",
        field: "launchValueString",
        sortField: "launchValue",
        searchField: "launchValue",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Nº da parcela",
        field: "parcelNumber",
        sortField: "parcelNumber",
        searchField: "parcelNumber",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "200px",
        className: "text-truncate",
        bodyTemplate(data: IAccountReceivableParcelListItemEntity) {
          const { parcelNumber, numberOfParcels } = data;
          return (
            <>
              {parcelNumber}/{numberOfParcels}
            </>
          );
        },
      },
      {
        header: "Competência",
        field: "competencyName",
        orderable: true,
        searchable: true,
        searchField: "competencyId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "158px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCompetency({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Projeto",
        field: "projectName",
        orderable: true,
        searchable: true,
        searchField: "projectId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "130px",
        async getList(search = "", fetchMinLength = 10) {
          return searchProject({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Receber em",
        field: "receiveUntilString",
        orderable: true,
        searchable: true,
        searchField: "receiveUntil",
        sortField: "receiveUntil",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "200px",
      },
      {
        header: "Cliente",
        field: "customerName",
        searchField: "customerId",
        searchable: true,
        orderable: true,
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 50) {
          return searchCustomer({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "CNPJ / CPF / Documento",
        field: "customerDocument",
        searchable: true,
        orderable: true,
        className: "text-truncate",
        width: "206px",
      },
      {
        header: "Método de pagamento",
        field: "paymentMethodDescription",
        searchable: true,
        orderable: true,
        sortField: "paymentMethod",
        searchField: "paymentMethod",
        columnType: EColumnType.enum,
        enumObject: EAccountReceivablePaymentMethod,
        enumLang: accountReceivablePaymentMethodLang,
        className: "text-truncate",
        width: "216px",
      },
      {
        header: "Classificação USP",
        field: "classificationUspName",
        orderable: true,
        searchable: true,
        searchField: "classificationUspId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "180px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationUsp({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },
      {
        header: "Descrição",
        field: "description",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Observação",
        field: "observation",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Conta de pagamento",
        field: "paymentAccountName",
        orderable: true,
        searchable: true,
        searchField: "paymentAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "206px",
        async getList(search = "", fetchMinLength = 10) {
          return searchPaymentAccount({
            search,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      {
        header: "Classificação contábil",
        field: "classificationAccountName",
        orderable: true,
        searchable: true,
        searchField: "classificationAccountId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "212px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationAccount({
            search,
            companyGroupId: id,
            payloadOptions: {
              length: fetchMinLength,
              showRecordsTotal: true,
            },
          });
        },
      },

      {
        header: "Centro de Custo",
        field: "costCenterName",
        orderable: true,
        searchable: true,
        searchField: "costCenterId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "212px",
        async getList(search = "", fetchMinLength = 10) {
          return searchCostCenter({
            search,
            activesOnly: true,
            companyGroupId: id,
            payloadOptions: { length: fetchMinLength, showRecordsTotal: true },
          });
        },
      },
      /*
        Essa coluna só deverá ser exibida quando as classificações de rateio em
        contas a receber for publicado.
      {
        header: "Classificação de Rateio",
        field: "classificationAssessmentName",
        orderable: true,
        searchable: true,
        searchField: "classificationAssessmentId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "212px",
        async getList(search = "", fetchMinLength = 10) {
          return searchClassificationAssessment(id, search, fetchMinLength);
        },
      }, */
      {
        header: "Data de emissão",
        field: "issueDateString",
        searchable: true,
        orderable: true,
        searchField: "issueDate",
        sortField: "issueDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "176px",
      },
      {
        header: "Lançado por",
        field: "userNameCreated",
        searchable: true,
        orderable: true,
        searchField: "userCreated",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Data de modificação",
        field: "dateModifiedString",
        searchable: true,
        orderable: true,
        searchField: "dateModified",
        sortField: "dateModified",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Atualizado por",
        field: "userNameModified",
        searchable: true,
        orderable: true,
        searchField: "userModified",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Data de baixa",
        field: "terminationDateString",
        searchable: true,
        orderable: true,
        searchField: "terminationDate",
        sortField: "terminationDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "176px",
      },
      {
        header: "Baixado por",
        field: "terminationUserName",
        searchable: true,
        orderable: true,
        searchField: "terminationUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Status da documentação",
        field: "documentStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "documentStatus",
        searchField: "documentStatus",
        columnType: EColumnType.enum,
        enumObject: EAccountReceivableDocumentStatus,
        enumLang: accountReceivableDocumentStatusLang,
        className: "text-truncate",
        width: "230px",
      },
      {
        header: "Status da parcela",
        field: "statusDescription",
        searchable: false,
        orderable: true,
        searchField: "status",
        columnType: EColumnType.enum,
        enumObject: EAccountReceivableStatus,
        enumLang: accountReceivableStatusLang,
        width: "200px",
        className: "text-truncate",
        bodyTemplate(rowData: IAccountReceivableParcelListItemEntity) {
          const { status, statusDescription } = rowData;
          return (
            <BadgeStatus title={statusDescription} status={status}>
              {statusDescription}
            </BadgeStatus>
          );
        },
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | EAccountReceivableStatus;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }

          const status = value as EAccountReceivableStatus;
          return (
            <BadgeStatus title={label} status={status}>
              {label}
            </BadgeStatus>
          );
        },
      },
      {
        header: "Status devolução",
        field: "returnStatusDescription",
        searchable: true,
        orderable: true,
        sortField: "returnStatus",
        searchField: "returnStatus",
        columnType: EColumnType.enum,
        enumObject: EReturnStatus,
        enumLang: returnStatusLang,
        className: "text-truncate",
        width: "210px",
      },
      {
        header: "Status Documento Fiscal",
        field: "invoiceStatusString",
        searchable: true,
        orderable: true,
        sortField: "invoiceStatus",
        searchField: "invoiceStatus",
        columnType: EColumnType.enum,
        enumObject: EInvoiceStatus,
        enumLang: invoiceStatusLang,
        className: "text-truncate",
        width: "210px",
        bodyTemplate(rowData: IAccountReceivableParcelListItemEntity) {
          const { invoiceStatus, invoiceStatusString } = rowData;

          return (
            <BadgeInvoiceStatus
              title={invoiceStatusString}
              invoiceStatus={invoiceStatus}
            >
              {invoiceStatusString}
            </BadgeInvoiceStatus>
          );
        },
        filterItemTemplate({
          value,
          label,
        }: {
          value: string | EEnumListBoxOptions | EInvoiceStatus;
          label: string;
        }) {
          if (
            value === EEnumListBoxOptions.empty ||
            value === EEnumListBoxOptions.selectAll
          ) {
            return label;
          }

          const invoiceStatus = value as EInvoiceStatus;

          return (
            <BadgeInvoiceStatus title={label} invoiceStatus={invoiceStatus}>
              {label}
            </BadgeInvoiceStatus>
          );
        },
      },
      {
        header: "Data do cancelamento",
        field: "deleteDateString",
        searchable: true,
        orderable: true,
        searchField: "deleteDate",
        sortField: "deleteDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Cancelado por",
        field: "deleteUserName",
        searchable: true,
        orderable: true,
        searchField: "deleteUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Motivo do cancelamento",
        field: "deleteReason",
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Data de ret. baixa",
        field: "chargebackDateString",
        searchable: true,
        orderable: true,
        searchField: "chargebackDate",
        sortField: "chargebackDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Data do lançamento",
        field: "dateCreatedString",
        searchable: true,
        orderable: true,
        searchField: "dateCreated",
        sortField: "dateCreated",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Baixa retirada por",
        field: "chargebackUserName",
        searchable: true,
        orderable: true,
        searchField: "chargebackUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      {
        header: "Valor devolvido",
        field: "returnValueString",
        sortField: "returnValue",
        searchField: "returnValue",
        searchable: true,
        orderable: true,
        columnType: EColumnType.numeric,
        width: "200px",
        className: "text-truncate",
      },
      {
        header: "Data da devolução",
        field: "returnDateString",
        searchable: true,
        orderable: true,
        searchField: "returnDate",
        sortField: "returnDate",
        columnType: EColumnType.date,
        className: "text-truncate",
        width: "186px",
      },
      {
        header: "Devolvido por",
        field: "returnUserName",
        searchable: true,
        orderable: true,
        searchField: "returnUserId",
        columnType: EColumnType.relationship,
        className: "text-truncate",
        width: "160px",
        async getList(search = "", fetchMinLength = 10) {
          return searchUser({
            search,
            payloadOptions: { length: fetchMinLength },
          });
        },
      },
      { field: "accountReceivableId", hidden: true },
      { field: "numberOfParcels", hidden: true },
      { field: "isTax", hidden: true },
      { field: "status", hidden: true },
      { field: "returnStatus", hidden: true },
      { field: "customerRetainFee", hidden: true },
      { field: "isFeeRetention", hidden: true },
      { field: "remittanceDate", hidden: true },
      { field: "remittanceUserId", hidden: true },
      { field: "remittanceUserName", hidden: true },
      { field: "fuspSendingFailureReason", hidden: true },

      // o campo projectName eh obrigatorio na request
      // pois eh usado na modal de devolucao
      { field: "projectName", hidden: true },

      // os campos abaixo sao obrigatorios na request
      // pois sao usados na modal de gerar NF
      { field: "companyName", hidden: true },
      { field: "documentNumber", hidden: true },
      { field: "customerName", hidden: true },
      { field: "value", hidden: true },
      { field: "municipalActivityClassificationId", hidden: true },
      { field: "municipalActivityClassifictionCode", hidden: true },
      { field: "cnaeClassificationId", hidden: true },
      { field: "cnaeClassificationCode", hidden: true },
      { field: "issAliquot", hidden: true },
      { field: "invoiceServiceDescription", hidden: true },
      { field: "invoiceDeniedReason", hidden: true },
      { field: "launchValue", hidden: true },
      { field: "serviceSupplyStateId", hidden: true },
      { field: "serviceSupplyStateName", hidden: true },
      { field: "serviceSupplyCityId", hidden: true },
      { field: "serviceSupplyCityName", hidden: true },
      { field: "irAliquot", hidden: true },
      { field: "csllAliquot", hidden: true },
      { field: "pisAliquot", hidden: true },
      { field: "cofinsAliquot", hidden: true },
    ];
  }, [
    id,
    searchClassificationAccount,
    searchClassificationUsp,
    searchCompany,
    searchCompetency,
    searchCostCenter,
    searchCustomer,
    searchPaymentAccount,
    searchProject,
    searchUser,
  ]);

  return {
    columns,
  };
}
