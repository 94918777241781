import { FormProvider, useFieldArray } from "react-hook-form";
import { IDebtImportForm } from "../../../domain/entities/debtImportTypes";
import { useAttachmentsHandler } from "../../hooks/useAttachmentsHandler";
import { useDebtImportPage } from "../../hooks/useDebtImportPage";
import { useValidationStep } from "../../hooks/useValidationStep";
import { RequestProgressModal } from "../RequestProgressModal";
import { StepsToolbar } from "../StepsToolbar";
import { ValidationGrid } from "../ValidationGrid";
import { ValidationStepDropdown } from "../ValidationStepDropdown";
import { Container } from "./styles";
import { AttachmentsModal } from "../AttachmentsModal";

export function ValidationStep() {
  const { form, getFormIndex } = useDebtImportPage();

  const { control, getValues } = form;

  const {
    state,
    handleFormRemoved,
    handleSelectionChange,
    handleOpenAttachmentModal,
    handleCloseAttachmentModal,
  } = useValidationStep();

  const {
    isBulk,
    rowData,
    progressModalState,
    getStorageFilebyId,
    attachmentTypeCheck,
    listAttachmentTypes,
    fetchRpaParamOptions,
    generateRpaAttachment,
    updateAttachmentsBarcode,
    handleAttachmentsSubmission,
    fetchCommercialProposalEnums,
    generateVariableAdditionalAttachment,
    generateCommercialProposalAttachment,
  } = useAttachmentsHandler({
    handleCloseAttachmentModal,
    selection: state.selection,
    formIndex: state.attachmentsModal.formIndex,
  });

  const { fields, remove } = useFieldArray<
    IDebtImportForm,
    "imports",
    "formId"
  >({ control, name: "imports", keyName: "formId" });

  const handleFormsRemoval = (formsIds: string[]) => {
    const indexes = formsIds.map(id => {
      return getFormIndex(id);
    });
    remove(indexes);
    handleFormRemoved(formsIds);
    const finalLength = getValues("imports")?.length || 0;
    return finalLength === 0;
  };

  return (
    <Container>
      <StepsToolbar>
        <ValidationStepDropdown
          selection={state.selection}
          handleFormsRemoval={handleFormsRemoval}
          onOpenAttachmentModal={handleOpenAttachmentModal}
        />
      </StepsToolbar>
      <FormProvider {...form}>
        <ValidationGrid
          fields={fields}
          selection={state.selection}
          handleFormsRemoval={handleFormsRemoval}
          handleSelectionChange={handleSelectionChange}
          handleOpenAttachmentModal={handleOpenAttachmentModal}
        />
      </FormProvider>
      <AttachmentsModal
        rowData={rowData}
        onClose={handleCloseAttachmentModal}
        isOpen={state.attachmentsModal.isOpen}
        getStorageFilebyId={getStorageFilebyId}
        attachmentTypeCheck={attachmentTypeCheck}
        listAttachmentTypes={listAttachmentTypes}
        fetchRpaParamOptions={fetchRpaParamOptions}
        generateRpaAttachment={generateRpaAttachment}
        onAttachmentsSubmit={handleAttachmentsSubmission}
        updateAttachmentsBarcode={updateAttachmentsBarcode}
        selectionCount={isBulk ? state.selection.length : undefined}
        fetchCommercialProposalEnums={fetchCommercialProposalEnums}
        generateCommercialProposalAttachment={
          generateCommercialProposalAttachment
        }
        generateVariableAdditionalAttachment={
          generateVariableAdditionalAttachment
        }
      />
      {isBulk ? (
        <RequestProgressModal
          mode="indeterminate"
          isOpen={progressModalState.isOpen}
        />
      ) : null}
      {!isBulk ? (
        <RequestProgressModal
          mode="determinate"
          total={progressModalState.total}
          isOpen={progressModalState.isOpen}
          loaded={progressModalState.loaded}
        />
      ) : null}
    </Container>
  );
}
