import { format } from "date-fns";
import { IClassificationAccountEntity } from "../../../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IClassificationUspEntity } from "../../../../../classificationUsp/domain/entities/classificationUspEntity";
import { ICompanyEntity } from "../../../../../company/domain/entities/companyEntity";
import { ICompetencyEntity } from "../../../../../competency/domain/entities/competencyEntity";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IUserEntity } from "../../../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ICostCenterEntity } from "../../../../../costCenter/domain/entities/costCenterEntity";
import { ICustomerEntity } from "../../../../../customer/domain/entities/customerEntity";
import { IPaymentAccountEntity } from "../../../../../paymentAccounts/domain/entities/paymentAccountEntity";
import { IProjectEntity } from "../../../../../projects/domain/entities/projectEntity";
import { IGetAccountReceivableContract } from "../../domain/contracts/getAccountReceivableContract";
import { IAccountReceivableAttachmentEntity } from "../../domain/entities/accountReceivableAttachmentEntity";
import { AccountReceivableFormEntity } from "../../domain/entities/accountReceivableFormEntity";
import { IAccountReceivableModel } from "../models/accountReceivableModel";

export class GetAccountReceivableService
  implements IGetAccountReceivableContract
{
  private readonly endpoint = "/AccountsReceivable";
  private readonly userEntity: IUserEntity | null = null;

  constructor(
    getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    this.userEntity = getUserLocalService.get();
  }

  async getAccountReceivable(receivableId: string) {
    const receivableData = await this.getData(receivableId);
    const accountReceivableParcels = this.normalizeParcels(receivableData);
    const [
      storageFiles,
      company,
      project,
      customer,
      competency,
      costCenter,
      paymentAccount,
      classificationUsp,
      classificationAccount,
    ] = await Promise.all([
      this.getStorageFiles(receivableId),
      this.getCompany(receivableData?.companyId),
      this.getProject(receivableData?.projectId),
      this.getCustomer(receivableData?.customerId),
      this.getCompetency(receivableData?.competencyId),
      this.getCostCenter(receivableData?.costCenterId),
      this.getPaymentAccount(receivableData?.paymentAccountId),
      this.getClassificationUsp(receivableData?.classificationUspId),
      this.getClassificationAccount(receivableData?.classificationAccountId),
    ]);

    const dtIssueDate = new Date(receivableData.issueDate);
    const dtInitialReceipt = new Date(receivableData.initialReceipt);

    const accountReceivable = new AccountReceivableFormEntity({
      project,
      company,
      customer,
      costCenter,
      competency,
      storageFiles,
      paymentAccount,
      classificationUsp,
      classificationAccount,
      id: receivableData.id,
      accountReceivableParcels,
      customerDocument: receivableData.customerDocument,
      description: receivableData?.description || "",
      documentNumber: receivableData.documentNumber,
      duplicateAccountReceivableId: receivableData.duplicateAccountReceivableId,
      initialReceipt: format(dtInitialReceipt, "dd/MM/yyyy"),
      invoiceServiceDescription: receivableData.invoiceServiceDescription,
      issueDate: format(dtIssueDate, "dd/MM/yyyy"),
      isLmsImportation: receivableData.isLmsImportation,
      numberOfParcels: receivableData.numberOfParcels,
      observation: receivableData?.observation || "",
      paymentMethod: {
        value: "",
        key: receivableData.paymentMethod,
      },
      returnStatus: receivableData.returnStatus,
      value: receivableData.value,
      documentStatus: {
        value: "",
        key: receivableData.documentStatus,
      },
    });

    return accountReceivable;
  }

  private getData(receivableId: string) {
    const url = `${this.endpoint}/${receivableId}`;

    return this.api.get<IAccountReceivableModel>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private async getStorageFiles(receivableId: string) {
    const url = `${this.endpoint}/${receivableId}/Attachments`;

    return this.api.get<IAccountReceivableAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private async getCompany(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/Companies/${id}`;

    const companyData = await this.api.get<ICompanyEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return {
      rawValue: companyData.id,
      label: companyData.assumedName,
      metadata: {
        hasProject: companyData?.hasProject || false,
      },
    };
  }

  private async getProject(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/Projects/${id}`;

    const projectData = await this.api.get<IProjectEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return {
      rawValue: projectData.id,
      label: projectData.fullName,
    };
  }

  private async getCustomer(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/Customers/${id}`;

    const customerData = await this.api.get<ICustomerEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return {
      rawValue: customerData.id,
      label: customerData.name,
    };
  }

  private async getCompetency(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/Competencies/${id}`;

    const competencyData = await this.api.get<ICompetencyEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return {
      rawValue: competencyData.id,
      label: competencyData.name,
      metadata: {
        isUsp: competencyData?.isUsp || false,
      },
    };
  }

  private async getCostCenter(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/CostCenters/${id}`;

    const costCenterData = await this.api.get<ICostCenterEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return {
      rawValue: costCenterData.id,
      label: costCenterData.acronym,
      metadata: costCenterData,
    };
  }

  private async getPaymentAccount(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/PaymentAccounts/${id}`;

    const paymentAccountData = await this.api.get<IPaymentAccountEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return {
      rawValue: paymentAccountData.id,
      label: paymentAccountData.name,
    };
  }

  private async getClassificationUsp(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/ClassificationsUsp/${id}`;

    const classificationUspData = await this.api.get<IClassificationUspEntity>(
      url,
      {
        headers: {
          Authorization: `Bearer ${this.userEntity?.token}`,
        },
      },
    );

    return {
      rawValue: classificationUspData.id,
      label: classificationUspData.name,
    };
  }

  private async getClassificationAccount(id?: string | null) {
    if (!id) {
      return null;
    }

    const url = `/ClassificationAccounts/${id}`;

    const classificationAccountData =
      await this.api.get<IClassificationAccountEntity>(url, {
        headers: {
          Authorization: `Bearer ${this.userEntity?.token}`,
        },
      });

    return {
      rawValue: classificationAccountData.id,
      label: classificationAccountData.name,
      metadata: classificationAccountData,
    };
  }

  /**
   * A normalização das parcelas se faz necessária quando há inconsistências nos
   * valores parcelados e no valor total da conta a receber.
   *
   * Portanto, todos os valores são **arredondados** e a diferença que existir
   * é somada ao valor da última parcela.
   */
  private normalizeParcels(receivableData: IAccountReceivableModel) {
    const originalParcelList = receivableData?.accountReceivableParcels;

    if (!originalParcelList?.length) {
      return [];
    }

    const { numberOfParcels } = receivableData;

    const parceList = originalParcelList.map(parcel => {
      const receiveUntil = parcel?.receiveUntil
        ? format(new Date(parcel?.receiveUntil), "dd/MM/yyyy")
        : "";

      const terminationDate = parcel?.terminationDate
        ? format(new Date(parcel?.terminationDate), "dd/MM/yyyy")
        : "";

      const value = Number(Number(parcel.value).toString());

      return {
        ...parcel,
        value,
        receiveUntil,
        terminationDate,
      };
    });

    const sumOfModifiedParcels = parceList.reduce((acc, curr) => {
      return acc + Number(curr.value);
    }, 0);

    const totalDiff = Number(receivableData.value) - sumOfModifiedParcels;

    parceList[numberOfParcels - 1].value += Number(totalDiff.toFixed(2));

    return parceList;
  }
}
